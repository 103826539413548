import React from 'react';
import { BsArrowsMove } from 'react-icons/bs';
import { FaTelegramPlane, FaCog, FaSync } from 'react-icons/fa';
import { MdEmojiPeople } from 'react-icons/md';

export function ucfirst(text?: string) {
  return text ? text.charAt(0).toUpperCase() + text.slice(1).toLowerCase() : '';
}

export function threeDecimals(number?: number) {
  if (!number) return '';
  const numberSplitted = number.toString().split('.');
  return [numberSplitted[0], numberSplitted[1] ? `.${numberSplitted[1]}000`.slice(0, 4) : '.000'];
}

export function formatNumber3decimals(number: number) {
  const formattedNumber = threeDecimals(number);
  return (
    <>
      {formattedNumber[0]}
      <span className="decimals">{formattedNumber[1]}</span>
    </>
  );
}

export function corpsToIcon(corps: string) {
  switch (corps) {
    case 'Exécution':
    case 'Execution TR 1':
    case 'Execution TR 2':
      return <MdEmojiPeople />;
    case 'Temps de vol':
      return <FaTelegramPlane />;
    case 'Déplacement':
      return <BsArrowsMove />;
    case 'Difficulté':
      return <FaCog />;
    case 'Synchronisation':
      return <FaSync />;
    default:
      return null;
  }
}
