import React from 'react';
import trampolive from 'assets/trampolive.svg';
import { FaTwitter } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { getAppVersion } from 'reducers/main';

export default function Loader() {
  const version = useSelector(getAppVersion);
  return (
    <div className="loader-center">
      <img className="trampolive" src={trampolive} alt="trampolive" />
      <div className="by">
        <span>{`v${version}`}</span>
        by
        <a href="https://twitter.com/NicolasW_Graou">
          Nicolas Wurtz <FaTwitter />
        </a>
      </div>
      <div className="loaders">
        <div className="bounce ball1" />
        <div className="bounce ball2" />
        <div className="bounce ball3" />
        <div className="bounce ball4" />
      </div>
      <div className="text">Veuillez patienter...</div>
    </div>
  );
}
