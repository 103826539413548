import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import mainReducer from 'reducers/main';
import { ffgymApi } from '../api';

const persistConfig = {
  key: 'trampolive',
  storage,
};

const persistedMainReducer = persistReducer(persistConfig, mainReducer);

export const store = configureStore({
  reducer: {
    // counter: counterReducer,
    main: persistedMainReducer,
    [ffgymApi.reducerPath]: ffgymApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(ffgymApi.middleware),
});

export const persistore = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
