import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { server } from 'environment.json';
import { Event } from 'types/Events';
import { Results } from 'types/Results';

const ffgymApiURL = 'https://server.ffgym.fr/api/live/';
const auth = btoa(`${server.username}:${server.password}`);

export const ffgymApi = createApi({
  reducerPath: 'ffgymApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ffgymApiURL,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Basic ${auth}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllCurrentEvents: builder.query<Event[], void | string>({
      query: () => 'events?codeLevel=F',
    }),
    getAllResultsById: builder.query<Results[], string | undefined>({
      query: (id) => `results/${id}`,
    }),
  }),
});

export const { useGetAllCurrentEventsQuery, useGetAllResultsByIdQuery } = ffgymApi;
