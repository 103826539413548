import React from 'react';
import cx from 'classnames';
import { BestNote, CorpsMark } from 'types/Results';
import { formatNumber3decimals, corpsToIcon } from 'helpers';

type AthleteNotesProps = {
  notes?: CorpsMark[];
  bestNotes?: BestNote[];
  passageRank?: number;
  used?: boolean;
  passagesCount?: number;
};

export default function AthleteNotes({
  notes,
  bestNotes,
  passageRank,
  used = false,
  passagesCount,
}: AthleteNotesProps) {
  return (
    <div className={cx('details-notes', !used && 'not-used')}>
      {notes?.map((note) => (
        <div
          className={cx(
            'note execution',
            bestNotes &&
              bestNotes.find((bestNote) => bestNote.corps === note.corps)?.value === note.value &&
              'best',
            note.corps === 'Pénalité JA' && note.value !== 0 && 'penality',
            note.corps === 'Pénalité JA' && note.value === 0 && 'invisible'
          )}
          key={`detail-note-${note.id}`}
        >
          <small>{corpsToIcon(note.corps)}</small>
          <span className="value">{formatNumber3decimals(note.value)}</span>
        </div>
      ))}
      {passagesCount && passagesCount > 1 && <div className="passageRank">{passageRank}</div>}
    </div>
  );
}
