import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Entity } from 'types/Results';
import { colors, ColorsIndex } from 'components/colors';
import { ucfirst } from 'helpers';

type Props = {
  entities: Entity[];
};

function name(entity: Entity) {
  if (entity.type === 'ENS' && entity.entities?.length === 2) {
    return `${ucfirst(entity.entities[0].firstname)}, ${entity.entities[1].firstname}`;
  }
  return `${ucfirst(entity.firstname)} ${entity.lastname.slice(0, 1)}.`;
}

export default function ResultsBarCharts({ entities }: Props) {
  const data = entities.map((entity) => {
    const corps = entity.mark?.appMarks[0].passageMarks.find(
      (passage) => passage.value === entity.mark.value
    )?.corpsMarks;
    const execution1Value = corps?.find((mark) => mark.corps === 'Execution TR 1')?.value || 0;
    const execution2Value = corps?.find((mark) => mark.corps === 'Execution TR 2')?.value || 0;
    const executionValue =
      corps?.find((mark) => mark.corps === 'Exécution')?.value ||
      Math.round((execution1Value + execution2Value) / 0.002) / 1000 ||
      0;
    return {
      firstname: entity.firstname,
      lastname: entity.lastname,
      name: name(entity),
      Exécution: executionValue,
      Déplacement: corps?.find((mark) => mark.corps === 'Déplacement')?.value || 0,
      'Temps de vol': corps?.find((mark) => mark.corps === 'Temps de vol')?.value || 0,
      Difficulté: corps?.find((mark) => mark.corps === 'Difficulté')?.value || 0,
      Synchronisation: corps?.find((mark) => mark.corps === 'Synchronisation')?.value || 0,
      'Pénalité JA': corps?.find((mark) => mark.corps === 'Pénalité JA')?.value || 0,
    };
  });
  const colorsIndex: ColorsIndex = {
    Exécution: colors.turquoise,
    'Execution TR 1': colors.turquoise,
    'Execution TR 2': colors.turquoise,
    Déplacement: colors.blue,
    'Temps de vol': colors['dark-blue'],
    Difficulté: colors.orange,
    Synchronisation: colors.green,
    'Pénalité JA': colors.red,
  };
  return (
    <ResponsiveBar
      data={data}
      keys={[
        'Pénalité JA',
        'Exécution',
        'Execution TR 1',
        'Execution TR 2',
        'Déplacement',
        'Temps de vol',
        'Difficulté',
        'Synchronisation',
      ]}
      indexBy="name"
      margin={{ top: 10, right: 10, bottom: 60, left: 30 }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      theme={{
        legends: {
          text: {
            fill: colors.gray,
          },
        },
        fontSize: 8,
        axis: {
          ticks: {
            line: {
              stroke: colors['gray-gray'],
            },
            text: {
              fill: colors.gray,
              fontSize: 10,
            },
          },
        },
        grid: {
          line: {
            stroke: colors['gray-gray'],
            strokeWidth: 1,
          },
        },
      }}
      colors={(e) => colorsIndex[e.id as keyof ColorsIndex]}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickRotation: -40,
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      role="application"
      ariaLabel="Résultats"
      barAriaLabel={(e: any) => `${e.id}: ${e.formattedValue} in name: ${e.indexValue}`}
    />
  );
}
