import React, { useEffect } from 'react';
import {
  getEventsFilter,
  getGenreFilter,
  updateEventsFilter,
  updateGenreFilter,
} from 'reducers/main';
import { useAppDispatch } from 'reducers/hooks';
import Loader from 'components/Loader';
import dayjs from 'dayjs';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import 'dayjs/locale/fr';
import { useSelector } from 'react-redux';
import { Category } from 'types/Events';
import { useGetAllCurrentEventsQuery } from './api';

dayjs.locale('fr');

function testIfConcerned(disciplineData: Category, filter?: string) {
  const text = `${disciplineData.label}${disciplineData.labelDiscipline}`;
  return filter ? text.toLowerCase().includes(filter.toLowerCase()) : true;
}

export default function Events() {
  const { trackPageView } = useMatomo();
  const dispatch = useAppDispatch();
  const eventsFilter = useSelector(getEventsFilter);
  const genreFilter = useSelector(getGenreFilter);
  const { data: events, isLoading } = useGetAllCurrentEventsQuery('', {
    pollingInterval: 60000,
  });

  function getGenre(label: string) {
    if (label.toLowerCase().includes('femme')) return 'woman';
    if (label.toLowerCase().includes('homme')) return 'man';
    if (label.toLowerCase().includes('mixte') || label.toLowerCase().includes('mx')) return 'mixte';
    return 'other';
  }

  useEffect(() => {
    trackPageView({
      documentTitle: 'TrampOLive — Évènements',
    });
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div className="events">
      <div className="header">
        <div className="input">
          <span className="label">Filtre</span>
          <input
            type="text"
            value={eventsFilter}
            onChange={(e) => dispatch(updateEventsFilter(e.target.value.toString()))}
          />
        </div>
        <div className="genre-buttons">
          <button
            type="button"
            onClick={() => dispatch(updateGenreFilter(undefined))}
            className={!genreFilter ? 'selected' : ''}
          >
            Tous
          </button>
          <button
            type="button"
            onClick={() => dispatch(updateGenreFilter('woman'))}
            className={`woman ${genreFilter === 'woman' ? 'selected' : ''}`}
          >
            Femmes
          </button>
          <button
            type="button"
            onClick={() => dispatch(updateGenreFilter('man'))}
            className={`man ${genreFilter === 'man' ? 'selected' : ''}`}
          >
            Hommes
          </button>
          <button
            type="button"
            onClick={() => dispatch(updateGenreFilter('mixte'))}
            className={`mixte ${genreFilter === 'mixte' ? 'selected' : ''}`}
          >
            Mixte
          </button>
        </div>
      </div>
      <div className="container">
        {events &&
          events
            .filter((event) =>
              event.categories.find(
                (category) => category.codeDiscipline === 'TRA' || category.codeDiscipline === 'TUM'
              )
            )
            .map((event) => {
              const categories = event.categories
                .slice()
                .sort((a, b) => a.labelDiscipline.localeCompare(b.labelDiscipline));
              let discipline = '';
              return (
                <div className="event" key={event.id}>
                  <div className="place">
                    {event.place}
                    <span className="date">{dayjs(event.startDate).format('DD MMMM')}</span>—
                    <span className="date">{dayjs(event.endDate).format('DD MMMM YYYY')}</span>
                  </div>
                  <div className="label">{event.label}</div>
                  <div className="categories">
                    {categories
                      .filter(
                        (category) => !genreFilter || genreFilter === getGenre(category.label)
                      )
                      .filter((category) => testIfConcerned(category, eventsFilter))
                      .map((category) => {
                        const disciplineDisplay = category.labelDiscipline !== discipline && (
                          <div className="discipline">{category.labelDiscipline}</div>
                        );
                        discipline = category.labelDiscipline;
                        return (
                          <>
                            {disciplineDisplay}
                            <a
                              className={`category ${getGenre(category.label)} ${
                                category.label.toLowerCase().includes('finale') &&
                                !category.label.includes('1/2')
                                  ? 'finale'
                                  : ''
                              }`}
                              key={category.id}
                              href={`/results/${category.code}`}
                            >
                              <div className="label">
                                <span className="finale-type">
                                  {category.label.toLowerCase().includes('finale') &&
                                  !category.label.includes('1/2')
                                    ? 'F'
                                    : '½'}
                                </span>
                                {category.label
                                  .replace(' Femme', '')
                                  .replace(' Homme', '')
                                  .replace(' Mixte', '')
                                  .replace(' Mx', '')
                                  .replace(' 1/2 Finale', '')
                                  .replace(' 1/2 F', '')
                                  .replace(' Finale', '')}
                              </div>
                            </a>
                          </>
                        );
                      })}
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
}
