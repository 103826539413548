import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'reducers/store';

export interface MainState {
  appVersion?: string;
  eventId?: number;
  eventLabel?: string;
  eventsFilter?: string;
  resultsFilter?: string;
  genreFilter?: string;
}

const initialState: MainState = {
  appVersion: '0.0.0',
  eventId: undefined,
  eventLabel: undefined,
  eventsFilter: undefined,
  resultsFilter: undefined,
  genreFilter: undefined,
};

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    updateAppVersion: (state, action) => {
      state.appVersion = action.payload;
    },
    updateEventId: (state, action) => {
      state.eventId = action.payload;
    },
    updateEventLabel: (state, action) => {
      state.eventLabel = action.payload;
    },
    updateEventsFilter: (state, action) => {
      state.eventsFilter = action.payload;
    },
    updateResultsFilter: (state, action) => {
      state.resultsFilter = action.payload;
    },
    updateGenreFilter: (state, action) => {
      state.genreFilter = action.payload;
    },
  },
});

export const {
  updateAppVersion,
  updateEventId,
  updateEventLabel,
  updateEventsFilter,
  updateResultsFilter,
  updateGenreFilter,
} = mainSlice.actions;

export const getAppVersion = (state: RootState) => state.main.appVersion;
export const getEventId = (state: RootState) => state.main.eventId;
export const getEventLabel = (state: RootState) => state.main.eventLabel;
export const getEventsFilter = (state: RootState) => state.main.eventsFilter;
export const getResultsFilter = (state: RootState) => state.main.resultsFilter;
export const getGenreFilter = (state: RootState) => state.main.genreFilter;

export default mainSlice.reducer;
