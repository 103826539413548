import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'reducers/hooks';
import { getAppVersion, updateAppVersion } from 'reducers/main';

const APP_CURRENT_VERSION = '0.0.5';

export default function CheckVersion() {
  const dispatch = useAppDispatch();
  const appVersion = useSelector(getAppVersion);
  useEffect(() => {
    if (appVersion && appVersion !== APP_CURRENT_VERSION) {
      dispatch(updateAppVersion(APP_CURRENT_VERSION));
      window.location.reload();
    }
  }, []);
  return null;
}
