import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { store } from 'reducers/store';
import CheckVersion from 'components/CheckVersion';
import Events from 'Events';
import Results from 'Results';
import 'styles/main.scss';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

const instance = createInstance({
  urlBase: 'https://stats.nethenic.net',
  siteId: 20,
});

function Container() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <CheckVersion />
        <BrowserRouter>
          <Routes>
            <Route path="/results/:eventId" element={<Results />} />
            <Route path="/*" element={<Events />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // @ts-ignore
  <MatomoProvider value={instance}>
    <Container />
  </MatomoProvider>
);
