export type ColorsIndex = {
  Exécution: string;
  'Execution TR 1': string;
  'Execution TR 2': string;
  Déplacement: string;
  'Temps de vol': string;
  Difficulté: string;
  Synchronisation: string;
  'Pénalité JA': string;
};

export const colors = {
  red: '#f85252',
  orange: '#f88f52',
  'light-orange': '#fbce9e',
  turquoise: '#7acfb0',
  green: '#8acf7a',
  blue: '#00bdc8',
  'dark-blue': '#1c5588',
  'very-dark-gray': '#222',
  'dark-gray': '#333',
  'gray-gray': '#777',
  gray: '#aaa',
  'light-gray': '#ccc',
  'almost-white': '#f2f2f2',
  gold: '#d9be4c',
  silver: '#d0d2d7',
  bronze: '#c07a50',
};
