import React, { useEffect } from 'react';
import { useParams } from 'react-router';
// import results from 'bulkdatas/results_F0000529.48047.F1.json';
import ResultsBarCharts from 'components/ResultsBarCharts';
import Loader from 'components/Loader';
import { useGetAllResultsByIdQuery } from 'api';
import { TbArrowBackUp } from 'react-icons/tb';
import { corpsToIcon } from 'helpers';
import { useAppDispatch } from 'reducers/hooks';
import { useSelector } from 'react-redux';
import { getResultsFilter, updateResultsFilter } from 'reducers/main';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import AthleteResult from 'components/AthleteResults';
import { Entity } from 'types/Results';

function findBestNote(entities: Entity[], corps: string) {
  const listOfCorpsValues = entities.map(
    (entity) =>
      entity.mark?.appMarks[0].passageMarks
        .find((passage) => passage.value === entity.mark.value)
        ?.corpsMarks.find((corpsMark) => corpsMark.corps === corps)?.value
  );
  return Math.max(...listOfCorpsValues.filter((value) => value).map((value) => Number(value)));
}

function bestNotesConversion(entities: Entity[]) {
  return [
    {
      corps: 'Exécution',
      value: findBestNote(entities, 'Exécution'),
    },
    {
      corps: 'Execution TR 1',
      value: findBestNote(entities, 'Execution TR 1'),
    },
    {
      corps: 'Execution TR 2',
      value: findBestNote(entities, 'Execution TR 2'),
    },
    {
      corps: 'Temps de vol',
      value: findBestNote(entities, 'Temps de vol'),
    },
    {
      corps: 'Déplacement',
      value: findBestNote(entities, 'Déplacement'),
    },
    {
      corps: 'Difficulté',
      value: findBestNote(entities, 'Difficulté'),
    },
    {
      corps: 'Synchronisation',
      value: findBestNote(entities, 'Synchronisation'),
    },
  ];
}

export default function Results() {
  const { trackPageView } = useMatomo();
  const { eventId } = useParams();
  const dispatch = useAppDispatch();
  const resultsFilter = useSelector(getResultsFilter);
  const { data: results, isLoading } = useGetAllResultsByIdQuery(eventId, {
    pollingInterval: 60000,
  });
  const bestNotesList = results ? bestNotesConversion(results[0].entities) : [];

  useEffect(() => {
    if (results) document.title = `${results[0].labelDiscipline} — ${results[0].label}`;
  }, [results]);

  useEffect(() => {
    if (results)
      trackPageView({
        documentTitle: `${results[0].labelDiscipline} — ${results[0].label}`,
      });
  }, []);

  if (isLoading) {
    return (
      <div className="loader-center">
        <Loader />
      </div>
    );
  }

  return results ? (
    <div className="results-container">
      <div className="results-header">
        <div className="title">
          <div className="discipline">{results[0].labelDiscipline}</div>
          <div className="label">{results[0].label}</div>
        </div>
        <div className="input-filter">
          <div className="input">
            <span className="label">Filtre</span>
            <input
              type="text"
              value={resultsFilter}
              onChange={(e) => dispatch(updateResultsFilter(e.target.value.toString()))}
            />
          </div>
        </div>
        <a className="back-to-home" href="/">
          <TbArrowBackUp /> Retour à la liste
        </a>
      </div>
      <div className="results-body">
        <div className="charts">
          <div className="global-results">
            <ResultsBarCharts entities={results[0].entities} />
          </div>
        </div>
        <div className="legend">
          <div className="legend-item exe">
            {corpsToIcon('Exécution')}
            <span className="text">Exécution</span>
          </div>
          <div className="legend-item hd">
            {corpsToIcon('Déplacement')}
            <span className="text">Déplacement</span>
          </div>
          <div className="legend-item tof">
            {corpsToIcon('Temps de vol')}
            <span className="text">Temps de vol</span>
          </div>
          <div className="legend-item dif">
            {corpsToIcon('Difficulté')}
            <span className="text">Difficulté</span>
          </div>
          <div className="legend-item syn">
            {corpsToIcon('Synchronisation')}
            <span className="text">Synchro</span>
          </div>
        </div>
        <div className="results">
          {results[0].entities.map((entity) => (
            <AthleteResult
              entityData={entity}
              key={`athlete-${entity.id}`}
              bestNotes={bestNotesList}
              filter={resultsFilter}
            />
          ))}
        </div>
      </div>
    </div>
  ) : null;
}
