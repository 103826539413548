import React from 'react';
import { BestNote, Entity } from 'types/Results';
import { ucfirst, formatNumber3decimals } from 'helpers';
import AthleteNotes from 'components/AthleteNotes';
import cx from 'classnames';

type AthleteResultProps = {
  entityData: Entity;
  bestNotes: BestNote[];
  filter?: string;
};

function testIfConcerned(entityData: Entity, filter?: string) {
  const text = `${entityData.firstname}${entityData.lastname}${entityData.club}${entityData.city}`;
  return filter && text.toLowerCase().includes(filter.toLowerCase());
}

export default function AthleteResult({ entityData, bestNotes, filter }: AthleteResultProps) {
  return (
    <div className={cx('athlete-result', testIfConcerned(entityData, filter) && 'filtered')}>
      <div className="container">
        <div className="header">
          <div className="rank">{entityData.markRank < 1000 && entityData.markRank}</div>
          {entityData.type === 'ENS' && entityData.entities?.length === 2 ? (
            <div className="name">
              <div className="name-ens">
                {ucfirst(entityData.entities[0].firstname)}
                <span className="lastname">{entityData.entities[0].lastname}</span>
              </div>
              <div className="name-ens">
                {ucfirst(entityData.entities[1].firstname)}
                <span className="lastname">{entityData.entities[1].lastname}</span>
              </div>
            </div>
          ) : (
            <div className="name">
              {ucfirst(entityData.firstname)}
              <span className="lastname">{entityData.lastname}</span>
            </div>
          )}
          <div className="final-note">{formatNumber3decimals(entityData.mark?.value)}</div>
        </div>
        <div className="footer">
          <div className="infos">
            <div className="city">{entityData.city}</div>
            <div className="club">{entityData.club}</div>
          </div>
          <div className="notes">
            <AthleteNotes
              notes={
                entityData.mark?.appMarks[0].passageMarks.find(
                  (passage) => passage.value === entityData.mark.value
                )?.corpsMarks
              }
              bestNotes={bestNotes}
              passageRank={
                entityData.mark?.appMarks[0].passageMarks.find(
                  (passage) => passage.value === entityData.mark.value
                )?.passage
              }
              passagesCount={entityData.mark?.appMarks[0].passageMarks.length}
              used
            />
            {entityData.mark?.appMarks[0].passageMarks.length > 1 && (
              <AthleteNotes
                notes={
                  entityData.mark?.appMarks[0].passageMarks.find(
                    (passage) => passage.value !== entityData.mark.value
                  )?.corpsMarks
                }
                passageRank={
                  entityData.mark?.appMarks[0].passageMarks.find(
                    (passage) => passage.value !== entityData.mark.value
                  )?.passage
                }
                passagesCount={entityData.mark?.appMarks[0].passageMarks.length}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
